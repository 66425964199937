.metadata-detail-item {
  display: inline-block;
  vertical-align: top;
  line-height: @ax-line-height-s;
  font-size: @ax-font-size-s;

  .ax-breakpoint-m({
    display: block;
  });

  .value {
    display: inline-block;
  }

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }
}
