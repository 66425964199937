// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
//  UI 3.0 COMMON FEATURE VARIABLES ONLY
//
//  Check out the Axonify UI Kit Feature styles artboard for visual examples and usage details:
//  https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?type=design&node-id=7391-5593&mode=design&t=Rszjf8HezSfHbyOE-0
// - - - - - - -

/**
* @tokens UIKit:Programs
* @presenter Color
*/
// Programs
@ax-certification-color-00:   #FFF0EA;
@ax-certification-color-60:   #F96639;
@ax-certification-color-90:   #B44500;
@ax-certification-color-icon: @ax-certification-color-60;
@ax-certification-color-text: @ax-certification-color-90;
@ax-certification-color-bg:   @ax-certification-color-00;
@ax-color-programs:           #035E42;
@ax-color-training:           #029B55;

/**
* @tokens UIKit:Assessments
* @presenter Color
*/
// Assessments
@ax-assessment-color-00:     #E5DEF9;
@ax-assessment-color-60:     #812F99;
@ax-assessment-color-90:     #5018A8;
@ax-assessment-color-icon:   @ax-assessment-color-90;
@ax-assessment-color-text:   @ax-assessment-color-90;
@ax-assessment-color-bg:     @ax-assessment-color-00;
@ax-assessment-color-task:   #880E0E;

/**
* @tokens UIKit:Content
* @presenter Color
*/
// Content
@ax-color-article-40:         #D1E1FF;
@ax-color-article-80:         #2231AC;
@ax-color-article:            @ax-color-blue-80;
@ax-color-event-40:           #F9E1F7;
@ax-color-event-80:           #731D6E;
@ax-color-event:              #AA44A5;
@ax-color-external-link-40:   #E5EFD6;
@ax-color-external-link-80:   #365D13;
@ax-color-external-link:      #8DBA54;
@ax-color-post:               @ax-color-white;
@ax-color-question-40:        #D4F7EE;
@ax-color-question-80:        #0C5D41;
@ax-color-question:           #10A889;
@ax-task-color-00:            #FFE9E9;
@ax-task-color-60:            #CE2E0E;
@ax-task-color-90:            #BE2323;
@ax-task-color-icon:          @ax-task-color-90;
@ax-task-color-text:          @ax-task-color-60;
@ax-task-color-bg:            @ax-task-color-00;
@ax-color-topic-40:           #D6F7EF;
@ax-color-topic-80:           #035E42;

/**
* @tokens UIKit:Achievements
* @presenter Color
*/
// Achievements - Gamification
@ax-color-answer-streak:      #83C143;
@ax-color-graduation:         @ax-color-blue-60;
@ax-color-lost-game:          @ax-task-color-60;
@ax-color-training-frequent:  #46BAA1;
@ax-color-training-lifetime:  @ax-task-color-60;
@ax-color-training-frequency: #2FA58C;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Trophies
* @presenter Color
*/
// Trophies
@ax-color-gold:               #A88A3E;
@ax-color-silver:             #919195;
@ax-color-bronze:             #AA6F17;
/**
* @tokens-end
*/

:root {
  --ax-color-answer-streak:                @ax-color-answer-streak;
  --ax-color-training-frequency:           @ax-color-training-frequency;
  --ax-color-assessments-self-directed-60: @ax-assessment-color-60;
  --ax-color-gold:                         @ax-color-gold;
  --ax-color-silver:                       @ax-color-silver;
  --ax-color-bronze:                       @ax-color-bronze;
  --ax-assessment-color-00:                @ax-assessment-color-00;
  --ax-assessment-color-60:                @ax-assessment-color-60;
  --ax-assessment-color-90:                @ax-assessment-color-90;
  --ax-assessment-color-icon:              @ax-assessment-color-icon;
  --ax-assessment-color-text:              @ax-assessment-color-text;
  --ax-assessment-color-bg:                @ax-assessment-color-bg;
  --ax-assessment-color-task:              @ax-assessment-color-task;
  --ax-certification-color-00:             @ax-certification-color-00;
  --ax-certification-color-60:             @ax-certification-color-60;
  --ax-certification-color-90:             @ax-certification-color-90;
  --ax-certification-color-icon:           @ax-certification-color-icon;
  --ax-certification-color-text:           @ax-certification-color-text;
  --ax-certification-color-bg:             @ax-certification-color-bg;
  --ax-task-color-00:                      @ax-task-color-00;
  --ax-task-color-60:                      @ax-task-color-60;
  --ax-task-color-90:                      @ax-task-color-90;
  --ax-task-color-icon:                    @ax-task-color-icon;
  --ax-task-color-text:                    @ax-task-color-text;
  --ax-task-color-bg:                      @ax-task-color-bg;
}
