.icon--badge {
  .border-radius(@ax-border-radius-m);
  .flex-center;
  background-color: @black;
  color: @white;
  .flex(none);

  &-md {
    .size(3.4rem);
    font-size: 2rem;

    .ax-breakpoint-m({
      .size(4rem);
      font-size: 2.6rem;
    });
}

&-lg {
  .size(4rem);
  font-size: 2.4rem;

  .ax-breakpoint-m({
    .size(5.6rem);
    font-size: 3.2rem;
  });
}

&-app-zone {
  background-color: @app-zone-color;
}

&-reinforcement {
  background-color: @green;
}

&-certification {
  background-color: @ax-certification-color-icon;
}

&-intro-training {
  background-color: @intro-training-color;
}

&-guided-learning {
  background-color: @ax-color-programs;
}

&-refresher {
  background-color: @refresher-color;
}

&-exam {
  background-color: @formal-exam-color;
}

&-self-directed-paths {
  background-color: @training-color;
}

&-trophy {
  background-color: @ax-color-gold;
}

&-ok {
  background-color: @ax-color-icon-green-a11y;
}

&-graduated {
  background-color: @primary-blue;
}

&-frequent-training {
  background-color: @turquoise;
}

&-achievements {
  background-color: @red;
}
}
