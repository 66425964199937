// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

//   EXAMPLE
//   <button class="ax-button">Default button</button>
//   <button class="ax-button ax-button--branded">Branded button</button>
//   <button class="ax-button ax-button--ghost ax-button--m">Medium ghost button</button>
//   <button class="ax-button ax-button--icon">
//     <span class="icon-chevron_down"></span>
//   </button>
//   <button class="ax-button ax-button--icon-right">
//     Right aligned icon button
//     <span class="icon-create-dz"></span>
//   </button>

//   BUTTON MODIFIERS
//   - .ax-button - Default button with neutral colors
//   - .ax-button--[s/m/l] - Modify button height (small only for floating buttons)
//   - .ax-button--full-width - Take up the full width of the parent container
//   - .ax-button--branded - Primary button with defined brand colors (axButtonCommon.html)
//   - .ax-button--branded-secondary - Secondary button with defined brand colors (axButtonCommon.html)
//   - .ax-button--ghost - Tertiary button with no background or border, except on hover
//   - .ax-button--ghost-parent-hover - Behaves like ghost button, also highlights on parent element hover
//   - .ax-button--ghost-link-branded - Looks like a branded link but behaves like ghost button
//   - .ax-button--destructive - Red buttons used for permanent deleting actions
//   - .ax-button--icon[optional: -left/-right] - An icon only button, or text with a left/right aligned icon
//   - .ax-button--floating - A round icon button for specific use cases

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Default button
@_btn-default-background: @ax-color-white;
@_btn-default-background-hover: @ax-color-grey-20;
@_btn-default-background-disabled: @ax-color-grey-30;
@_btn-default-border: @_btn-border solid @ax-color-grey-40;
@_btn-default-border-hover: @_btn-border solid @ax-color-grey-60;
@_btn-default-border-disabled: @_btn-border solid @ax-color-grey-40;
@_btn-default-color-disabled: @ax-color-grey-40;

// Branded button
@_btn-branded-color-disabled: @ax-color-grey-50;
@_btn-branded-background-disabled: @ax-color-grey-30;
@_btn-branded-border-color-disabled: @ax-color-grey-30;

// Ghost button
@_btn-ghost-background: transparent;
@_btn-ghost-border: @_btn-border solid transparent;
@_btn-ghost-border-disabled: @_btn-border solid transparent;
@_btn-ghost-border-hover: @_btn-border solid @ax-color-grey-40;

// Destructive button
@_btn-destructive-background: transparent;
@_btn-destructive-background-hover: @ax-color-red-60;
@_btn-destructive-border: @_btn-border solid @ax-color-red-60;
@_btn-destructive-color: @_btn-destructive-background-hover;
@_btn-destructive-color-hover: @ax-color-white;

// Floating button
@_btn-floating-background: @ax-color-grey-10;
@_btn-floating-background-hover: @ax-color-grey-20;


@_btn-border: 0.1rem;
@_btn-color: @ax-color-grey-80;
@_btn-font: @ax-font-size-s;
@_btn-line-height-l: @ax-line-height-s;
@_btn-line-height-s: @ax-font-size-s;
@_btn-line-height: @_btn-line-height-l;
@_btn-padding-l: (@ax-spacing-s - @_btn-border) @ax-spacing-m;
@_btn-padding-m: (@ax-spacing-xs - @_btn-border) @ax-spacing-m;
@_btn-padding-s: (@ax-spacing-xxs - @_btn-border) @ax-spacing-s;
@_btn-size-l: @ax-spacing-xxl;
@_btn-size-m: @ax-spacing-xl;
@_btn-size-s: @ax-spacing-l;
@_btn-weight: @ax-font-weight-medium;

:root {
  --axButton-color: @_btn-color;
  --axButton-font: @_btn-font;
  --axButton-line-height: @_btn-line-height;
  --axButton-padding-l: @_btn-padding-l;
  --axButton-padding-m: @_btn-padding-m;
  --axButton-padding-s: @_btn-padding-s;
  --axButton-weight: @_btn-weight;

  // Default button
  --axButton-default-background: @_btn-default-background;
  --axButton-default-background-hover: @_btn-default-background-hover;
  --axButton-dafault-background-disabled: @_btn-default-background-disabled;
  --axButton-default-border: @_btn-default-border;
  --axButton-default-border-hover: @_btn-default-border-hover;
  --axButton-default-border-disabled: @_btn-default-border-disabled;
  --axButton-default-color-disabled: @_btn-default-color-disabled;

  // Branded button
  --axButton-branded-color-disabled: @_btn-branded-color-disabled;
  --axButton-branded-background-disabled: @_btn-branded-background-disabled;
  --axButton-branded-border-color-disabled: @_btn-branded-border-color-disabled;

  // Destructive button
  --axButton-destructive-background: @_btn-destructive-background;
  --axButton-destructive-background-hover: @_btn-destructive-background-hover;
  --axButton-destructive-border: @_btn-destructive-border;
  --axButton-destructive-color: @_btn-destructive-color;
  --axButton-destructive-color-hover: @_btn-destructive-color-hover;

  // Ghost button
  --axButton-ghost-background: @_btn-ghost-background;
  --axButton-ghost-border: @_btn-ghost-border;
  --axButton-ghost-border-disabled: @_btn-ghost-border-disabled;

  // Floating button
  --axButton-floating-background: @_btn-floating-background;
  --axButton-floating-background-hover: @_btn-floating-background-hover;
}

// - - - - - - - - - - - - - - - - -
//   MIXINS
// - - - - - - - - - - - - - - - - -
._FloatingIconSizes(@class-size, @button-size) {
  &.ax-button--@{class-size} {
    height: @button-size;
    width: @button-size;

    [class^="icon-"] {
      font-size: @ax-font-size-s;
      line-height: @button-size;
    }
  }
}

._destructive-button-variation {
  background-color: @_btn-destructive-background;
  border: @_btn-destructive-border;
  color: @_btn-destructive-color;

  &:hover,
  &.pressed {
    background-color: @_btn-destructive-background-hover;
    border: @_btn-destructive-border;
    color: @_btn-destructive-color-hover;
  }
}

// - - - - - - - - - - - - - - - - -
//   DEFAULT
// - - - - - - - - - - - - - - - - -
.ax-button {
  .border-radius(@ax-border-radius-m);
  .box-sizing(border-box);
  font-size: @_btn-font;
  .transition-property(~"background-color, border-color");
  .transition(all @ax-transition-speed @ax-transition-ease);
  background-color: @_btn-default-background;
  border: @_btn-default-border;
  color: @_btn-color;
  cursor: pointer;
  display: inline-block;
  font-weight: @_btn-weight;
  line-height: @_btn-line-height;
  text-align: center;
  vertical-align: middle;
  padding: @_btn-padding-l;

  &.focus-visible:focus,
  &:focus-visible {
    .ax-focus-ring();
  }

  &:hover,
  &.pressed {
    background-color: @_btn-default-background-hover;
    border: @_btn-default-border-hover;
    color: @ax-color-grey-80;
    text-decoration: none;
  }

  &:disabled,
  &.disabled,
  &--disabled {
    background-color: @_btn-default-background-disabled;
    border: @_btn-default-border-disabled;
    color: @ax-color-grey-80;

    &:hover,
    &.pressed {
      background-color: @_btn-default-background-disabled;
      border: @_btn-default-border-disabled;
      color: @ax-color-grey-80;
      cursor: not-allowed;
    }
  }

  &--s {
    padding: @_btn-padding-s;
  }

  &--m {
    padding: @_btn-padding-m;
  }

  // - - - - - - - - - - - - - - - - -
  //   BRANDED - SECONDARY
  // - - - - - - - - - - - - - - - - -
  &--branded-secondary:hover,
  &--branded-secondary.pressed {
    color: #fff;
  }

  // - - - - - - - - - - - - - - - - -
  //   GHOST
  // - - - - - - - - - - - - - - - - -
  &--ghost,
  &--ghost-parent-hover,
  &--ghost-link-branded {
    background-color: @_btn-ghost-background;
    border: @_btn-ghost-border;

    &:hover,
    &.pressed {
      // Branded hover styles in axButtonCommon.html
      background-color: @_btn-ghost-background;
    }

    &:disabled,
    &.disabled,
    &--disabled {
      background-color: @_btn-ghost-background;
      border: @_btn-ghost-border;
      color: @_btn-default-color-disabled;
  
      &:hover,
      &.pressed {
        background-color: @_btn-ghost-background;
        border: @_btn-ghost-border;
        color: @_btn-default-color-disabled;
        cursor: not-allowed;
      }
    }
  }

  // - - - - - - - - - - - - - - - - -
  //   DESTRUCTIVE
  // - - - - - - - - - - - - - - - - -
  &--destructive {
    ._destructive-button-variation
  }

  // - - - - - - - - - - - - - - - - -
  //   ICON
  // - - - - - - - - - - - - - - - - -
  [class^="icon-"] {
    display: inline-block;
    font-size: @_btn-line-height-l;
    line-height: @_btn-line-height-l;
    vertical-align: top;

    // Undo base styles for icons within a link
    color: inherit;
    opacity: 1;
  }

  &--icon {
    .size(@_btn-size-l);
    padding: 0;

    [class^="icon-"] {
      line-height: @_btn-size-l - (@_btn-border * 2);
    }

    &.ax-button--s {
      .size(@_btn-size-s);

      [class^="icon-"] {
        line-height: @_btn-size-s - (@_btn-border * 2);
      }
    }

    &.ax-button--m {
      .size(@_btn-size-m);

      [class^="icon-"] {
        line-height: @_btn-size-m - (@_btn-border * 2);
      }
    }
  }

  &--icon-left {
    [class^="icon-"] {
      padding-right: @ax-spacing-s;
    }
  }

  &--icon-right {
    [class^="icon-"] {
      padding-left: @ax-spacing-s;
    }
  }


  &.ax-button--icon-left.ax-button--icon-right {
    [class^="icon-"] {
      &:first-of-type {
        padding-right: @ax-spacing-s;
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
        padding-left: @ax-spacing-s;
      }
    }
  }

  // - - - - - - - - - - - - - - - - -
  //   FLOATING
  // - - - - - - - - - - - - - - - - -
  &&--floating {
    .border-radius(50%);
    .box-shadow(@ax-box-shadow-s; );
    background-color: @_btn-floating-background;
    border: none;
    height: @_btn-size-l;
    padding: 0;
    width: @_btn-size-l;

    &:hover {
      background-color: @_btn-floating-background-hover;
    }

    ._FloatingIconSizes(~'m', @_btn-size-m);
    ._FloatingIconSizes(~'s', @_btn-size-s);
  }

  ;

  // - - - - - - - - - - - - - - - - -
  //   FULL WIDTH
  // - - - - - - - - - - - - - - - - -
  &--full-width {
    display: block;
    width: 100%;
  }

  &--text-align-left {
    text-align: left;
  }
}

:hover>.ax-button--ghost-parent-hover,
:hover>.MuiButton--ghost-parent-hover,
:hover>.MuiIconButton--ghost-parent-hover,
.container__ax-button-ghost-parent:hover .ax-button--ghost-parent-hover:not(:hover),
.container__ax-button-ghost-parent:hover .MuiButton--ghost-parent-hover:not(:hover),
.container__ax-button-ghost-parent:hover .MuiIconButton--ghost-parent-hover:not(:hover) {
  border: @_btn-ghost-border-hover;
}

.ax-button--branded {
  background-color: var(--axButton-branded-background);
  border-color: var(--axButton-branded-background);
  color: var(--axButton-branded-color);
}

.ax-button--branded-secondary,
.ax-button--ghost:hover,
.ax-button--ghost.pressed,
.ax-button--ghost-parent-hover:hover,
.ax-button--ghost-parent-hover.pressed,
.filterable-selector-item.selected,
.filterable-selector-item.selected:hover {
  border-color: var(--axButton-branded-background);
}

.ax-button--branded:hover,
.ax-button--branded.pressed,
.ax-button--branded-secondary:hover,
.ax-button--branded-secondary.pressed {
  background-color: var(--axButton-branded-background-hover);
  border-color: var(--axButton-branded-background-hover);
  color: var(--axButton-branded-color-hover);
}

.ax-button--ghost-link-branded {
  color: var(--ax-link-color);
}

.ax-button--ghost-link-branded:hover,
.ax-button--ghost-link-branded.pressed {
  border-color: var(--ax-link-color);
  color: var(--ax-link-color);
}
