@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.todays-activities {
  &__card {
    word-break: break-word;
    .size(initial);
    .flex(1 1 auto);

    .subtext-header-title {
      .flex-grow();

      &--progress-wrapper {
        .display-flex();

        .progressBarRegion {
          .screen-md-portrait-min({
            .flex-grow();
          });

          .progress-bar {
            display: none;
            .screen-md-portrait-min({
              display: block;
              height: 7px;
              .progress-bar-inset {
                background-color: @primary-blue;
              }
            });
          }
        }

        p {
          color: @secondary-text-color;
        }
      }
      .screen-md-portrait-min({
        .subtext-header-title__message {
          margin-left: @ax-spacing-m;
        }
      });
    }

    .base-card__view-all {
      position: absolute;
      top: @ax-spacing-m;
      right: @ax-spacing-m;
    }
  }

  &__lower--region {
    width: 100%;
    .display-flex();
    .flex-direction(column);
    .justify-content(space-between);

    .screen-md-landscape-min({
      .flex-direction(row);
    });

    .base-card__meta {
      margin-bottom: @ax-spacing-m;
      margin-top: 1.2rem;

      .screen-md-portrait-min({
        margin: 0;
        min-height: 0;
      });
    }

    .metadata-detail-item {
      display: block;
      margin-top: @ax-spacing-xs;

      .screen-md-portrait-min({
        display: inline-block;
        margin-top: 0;
      });
    }

    & > div {
      .flex(1 0 auto);
      .display-flex();
      .flex-direction(column);

      .screen-md-portrait-min({
        .flex(0 1 auto);
      });
    }

    .lower-region__button--wrapper {
      .flex(0 0 auto);
      .flex-direction(row);
      .align-items(center);
      .justify-content(space-between);
      flex-wrap: nowrap;
      margin: 0 -@ax-spacing-xs;

      .todays-activities-card-button {
      .full-width();
        height: auto;
        font-size: @ax-font-size-s;
        margin: 0 @ax-spacing-xs;

        .screen-md-portrait-min({
          width: auto;
          padding-right: @ax-spacing-m;
          padding-left: @ax-spacing-m;
          min-width: 10rem;
        });

        .action-text {
          .screen-md-portrait-min({
            white-space: nowrap;
          });
        }
      }
    }

    .screen-md-portrait-min({
      .flex-direction(row);
      .align-items(center);
      .justify-content(space-between);
    });
  }
}
