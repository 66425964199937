.bonuscharacter.assessment.bonuscharacter--fast-tracked {
  .display-flex();
  .align-items(center);
  max-width: 70rem;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;

  .bonuscharacter--image {
    // The width 14.5rem and height 12rem are the same used to define the coach dimensions in other places.
    width: 14.5rem;
    min-width: 14.5rem;
    height: 12rem;
    margin: auto 0 0;

    .ax-breakpoint-m({
      margin: 0;
      padding-top: 0;
    });
  }

  .bonuscharacter-text {
    .ax-breakpoint-m({
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    });

    h2 {
      margin: 0 0 @ax-spacing-xs;
      font-size: @ax-font-size-s;

      .ax-breakpoint-m({
        font-size: @ax-font-size-l;
        margin: @ax-spacing-m 0 @ax-spacing-xs;
      });

      .ax-breakpoint-l({
        margin: @ax-spacing-l 0 @ax-spacing-s;
      });
    }

    p {
      margin: 0;
      font-size: @ax-font-size-xs;
      line-height: @ax-line-height-xs;

      .ax-breakpoint-m({
        font-size: @ax-font-size-s;
        line-height: @ax-line-height-s;
      });
    }
  }
}

.bonuscharacter.assessment.bonuscharacter--fast-tracked-no-coach {
  height: auto;
  margin: @ax-spacing-m 0 @ax-spacing-m;

  .ax-breakpoint-m({
    margin: 0 auto @ax-spacing-s;
  });

  .bonuscharacter-text {
    margin-left: @ax-spacing-s;

    .ax-breakpoint-m({
      margin-left: 0;
    });
  }
}
